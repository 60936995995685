import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, FormControl, Typography } from '@mui/material';
import { Column, Spacer, Row, StepsHeader } from '../../components';
import { ToastbarContext } from '../../App';
import { Toast } from '../../models/Toast';
import { MutationStatus, useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { OffboardingWizard, offboardingWizardKey } from '../../services/offboarding';
import { UpdateSignUpState } from '../../data/signUp/mutations';
import { SignUpCompartments, signUpStorageKey } from '../../data/signUp';
import { OrgSignUpStateData } from '../../models/SignUpData';
import { StepsHeaderTypes } from '../../models/StepsHeaderData';
import { CustomBlurbTextBox } from './CustomBlurb';
import { CreateCustomBlurb, UpdateCustomBlurb } from '../../data/organizationMetadata/mutations';
import { OrganizationCompartments, organizationStorageKey } from '../../data/organization';
import { OrganizationData } from '../../models/OrganizationData';
import { useLoading } from '../../hooks/useLoading';
import MemberSignUpUrl from '../dash/MemberSignUpUrl';
import { OrganizationMetadata } from '../../models/OrganizationMetadata';
import {
  OrganizationMetadataCompartments,
  organizationMetadataStorageKey,
} from '../../data/organizationMetadata';
import { useToDoList } from '../../hooks/useToDoList';

const CustomUrl = () => {
  const appStorage = useAppStorage();
  const navigate = useNavigate();
  const location = useLocation();
  const { setToast } = useContext(ToastbarContext);
  const { setLoading } = useLoading();

  const updateSignUpState = useMutation(new UpdateSignUpState());
  const createCustomBlurb = useMutation(new CreateCustomBlurb());
  const updateCustomBlurb = useMutation(new UpdateCustomBlurb());

  const offboardingWizard = useService<OffboardingWizard>(offboardingWizardKey);
  const signUpDataCache = appStorage.retrieve<SignUpCompartments>(signUpStorageKey);
  const organizationDataCache =
    appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
  const organizationMetadataCache = appStorage.retrieve<OrganizationMetadataCompartments>(
    organizationMetadataStorageKey,
  );

  const { offboardingComplete } = useObservable(
    signUpDataCache.observe$<OrgSignUpStateData>('orgSignUpState'),
  ) ?? { offboardingComplete: false };
  const organizationData = useObservable(
    organizationDataCache.observe$<OrganizationData>('organization'),
  );
  const organizationMetadata = useObservable(
    organizationMetadataCache.observe$<OrganizationMetadata>('metadata'),
  );
  const saveCustomBlurb = useMemo(() => {
    return !organizationMetadata ? createCustomBlurb : updateCustomBlurb;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationMetadata]);

  const todos = useToDoList() ?? [];
  const headerSteps = todos.some(t => t.text.includes('Restrict'))
    ? StepsHeaderTypes.DefaultOffboarding
    : StepsHeaderTypes.MemberPaysOffboarding;
  const [isLimitExceeded, setIsLimitExceeded] = useState(false);
  const [customBlurb, setCustomBlurb] = useState('');

  const current = location.pathname;
  const { activeStep, totalSteps } = offboardingWizard.getStepInfo(current);

  useEffect(() => {
    if (saveCustomBlurb.error) {
      setToast(
        new Toast({
          severity: 'error',
          open: true,
          message: 'Error saving custom blurb. Please try again.',
        }),
      );
      return;
    } else if (saveCustomBlurb.status === MutationStatus.Complete) {
      setToast(
        new Toast({
          severity: 'success',
          open: true,
          message: 'Custom blurb saved!',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveCustomBlurb.status]);

  useEffect(() => {
    if (updateSignUpState.error) {
      setToast(new Toast({ severity: 'error', open: true }));
      return;
    } else if (updateSignUpState.status === MutationStatus.Complete) {
      setToast(
        new Toast({
          message: 'Member sign up page successfully set up!',
          severity: 'success',
          open: true,
          autoHideDuration: 3000,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSignUpState.status]);

  const navBack = () => {
    navigate(-1);
  };

  const onFinish = async () => {
    setLoading(true);
    const next = offboardingWizard.nextStep(location);

    if (activeStep === totalSteps) {
      if (!offboardingComplete) {
        offboardingWizard.offboardingCompleted();
        await saveCustomBlurb.action({
          organizationId: organizationData?.id ?? 0,
          signupPageText: customBlurb,
        });
      } else {
        setToast(
          new Toast({
            message: 'Member sign up page updated!',
            severity: 'success',
            open: true,
          }),
        );
      }
    }

    await updateSignUpState.action({
      lastCompletedStep: current,
      offboardingComplete: activeStep === totalSteps,
    });

    navigate(next.route);
    setLoading(false);
  };

  return (
    <Column style={{ margin: '0px 100px' }}>
      <Spacer height='xs' />
      <StepsHeader activeStep={activeStep} totalSteps={totalSteps} headerSteps={headerSteps} />
      <Spacer height='xs' />
      <Typography variant='h1' color='secondary'>
        Send out custom URL
      </Typography>
      <Spacer height='xs' />
      <Typography variant='h2' color='info.main'>
        Notify your members of your custom sign up page.
      </Typography>
      <Spacer height='xs' />
      <MemberSignUpUrl />
      <Spacer height='xl' />
      <Typography variant='p20SemiBold' color='secondary'>
        Want to say something to your members on your custom sign up page?
      </Typography>
      <Spacer height='xxs' />
      <Typography variant='p16' fontStyle='italic' color='info.main'>
        Example: Welcome to your gateway to financial wellness with 3Nickels, the innovative app for
        financial health. This portal unlocks access to essential tools and resources for your
        financial journey. From budgeting, investing, and saving to personalized financial advice,
        3Nickels simplifies personal finance, empowering you towards financial freedom. Start now
        and embrace your path to financial wellness.
      </Typography>
      <Spacer height='xxs' />
      <FormControl style={{ width: '50%' }}>
        <CustomBlurbTextBox
          onLimitExceeded={overLimit => setIsLimitExceeded(overLimit)}
          onChange={setCustomBlurb}
        />
      </FormControl>
      <Spacer height='sm' />
      <Row style={{ justifyContent: 'space-between' }}>
        <Button className='xs' variant='outlined' color='secondary' onClick={navBack}>
          Back
        </Button>
        <Button className='sm' color='primary' onClick={onFinish} disabled={isLimitExceeded}>
          {activeStep === totalSteps ? `Done` : `Next`}
        </Button>
      </Row>
      <Spacer height='lg' />
    </Column>
  );
};

export default CustomUrl;
