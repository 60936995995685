import styled from '@emotion/styled';
import BgLeft from '../../assets/svg/bg-left.svg';
import BgRight from '../../assets/svg/bg-right.svg';

export const MainWrapper = styled.div`
  display: flex;
`;

export const MainLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
`;

export const MainSection = styled.div`
  flex: 1 0 100%;
  display: flex;
  width: 100%;
  height: 100%;
  margin: auto;
  overflow: hidden;
`;

export const MainStyle = styled.div`
  flex: 1;
  overflow: auto;
  min-width: 300px;
  position: relative;
`;

export const MainContent = styled.div`
  flex: 1;
  overflow: auto;
  padding: 1rem;
  min-width: 300px;
  position: relative;
  background-attachment: local;

  background-image: url(${BgLeft});
  background-position: top left;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

export const MainContentRight = styled.div`
  flex: 1;
  overflow: auto;
  padding: 1rem;
  min-width: 300px;
  position: relative;
  background-attachment: local;

  background-image: url(${BgRight});
  background-position: top left;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;
