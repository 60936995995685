import { DataCache, IDataCommand, ScriniumServices, injectDataCache } from '@aesop-fables/scrinium';
import type { ICommandExecutor } from '@aesop-fables/scrinium';
import { LearningCompartments, learningStorageKey } from '..';
import { inject } from '@aesop-fables/containr';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { CourseData, PublicationStatusEnum, UnitData } from '../../../models/CourseData';
import { UnitApi } from '../../../api/apis/UnitApi';
import { findUnitSource } from './utils';
import { PublishUnit } from './PublishUnit';

export class AddOrUpdateUnit implements IDataCommand<UnitData, UnitData> {
  constructor(
    @injectDataCache(learningStorageKey) private readonly cache: DataCache<LearningCompartments>,
    @inject(ApiKeys.Unit) private readonly api: UnitApi,
    @inject(ScriniumServices.CommandExecutor) private readonly commands: ICommandExecutor,
  ) {}

  async execute(unit: UnitData): Promise<UnitData> {
    let response: UnitData | undefined;

    await this.cache.modify<CourseData[]>('courses', async courses => {
      if (unit.sourceCourseId) {
        if (unit.unitId) {
          const lessons = unit.lessons;
          let { data: updatedUnit } =
            unit.icon === null || unit.background === null
              ? await this.api.replaceUnit(unit)
              : await this.api.updateUnit(unit);

          // only publish if original unit was published
          if (unit.status === PublicationStatusEnum.PUBLISHED) {
            const publishedUnit = await this.commands.execute(PublishUnit, updatedUnit);
            updatedUnit = publishedUnit;
          }
          updatedUnit.lessons = lessons;
          response = updatedUnit;

          const courseIndex = findUnitSource(unit, courses);
          const course = courses[courseIndex];

          const units = course.units ?? [];
          // editing a unit deactivates/deletes the original unit and creates a new entry for the updated unit
          const newUnits = units
            .map(u => {
              if (
                (unit.active || unit.status === PublicationStatusEnum.DRAFT) &&
                u.originalUnitId === unit.originalUnitId
              ) {
                // if the unit being edited is active, set active to false
                if (u.active) {
                  return { ...u, active: false };
                }
                // if the unit being edited is a draft, delete that unit
                return null;
              }
              return u;
            })
            .filter((unit): unit is UnitData => unit !== null);
          const updatedUnits = [...newUnits, updatedUnit];
          course.units = updatedUnits;

          const newCourses = [...courses];
          newCourses[courseIndex].units = updatedUnits;

          return newCourses;
        }

        const courseIndex = findUnitSource(unit, courses);
        const course = courses[courseIndex];
        const { data } = await this.api.createUnit(unit);
        const newUnits = [...(course.units ?? []), data];
        course.units = newUnits;
        response = data;

        const newCourses = [...courses];
        newCourses[courseIndex].units = newUnits;

        return newCourses;
      }
      return courses;
    });

    return response as UnitData;
  }
}
