import { useContext, useEffect, useMemo, useState } from 'react';
import { KeyValueLabel } from '../../../../components';
import { ToastbarContext } from '../../../../App';
import { Toast } from '../../../../models/Toast';
import { Form } from 'react-final-form';
import { Button, Grid, Typography } from '@mui/material';
import { useCommands } from '../../../../helpers/useCommands';
import { AddOrUpdateUnit } from '../../../../data/learning/commands/AddOrUpdateUnit';
import { useObservableQuery } from '@aesop-fables/scrinium';
import { useNavigate, useParams } from 'react-router-dom';
import { FindUnitById } from '../../../../data/learning/queries/FindUnitById';
import { MediaData, MediaType } from '../../../../models/MediaData';
import MediaEditView from '../media/MediaEditView';
import { AxiosError } from 'axios';

export const unitIconKeyString = 'unit/icon';
export const unitBackgroundKeyString = 'unit/background';

const UnitEditView = () => {
  const { setToast } = useContext(ToastbarContext);
  const commands = useCommands();
  const navigate = useNavigate();
  const params = useParams();

  const { unitId, originalCourseId } = useMemo(
    () => ({
      unitId: parseInt(params.unitId ?? ''),
      originalCourseId: parseInt(params.courseId ?? ''),
    }),
    [params.unitId, params.courseId],
  );

  const unit = useObservableQuery(FindUnitById, unitId);
  const editing = useMemo(() => typeof unit !== 'undefined', [unit]);

  const [previewIcon, setPreviewIcon] = useState<MediaData | null>(null);
  const [previewBackground, setPreviewBackground] = useState<MediaData | null>(null);

  useEffect(() => {
    if (unit) {
      setPreviewIcon(unit.icon ?? null);
      setPreviewBackground(unit.background ?? null);
    }
  }, [unit]);

  const initialValues = useMemo(
    () => ({
      name: unit?.name || '',
      icon: unit?.icon,
      background: unit?.background,
    }),
    [unit],
  );

  const onCancel = () => {
    navigate(-1);
  };

  const onSubmit = async (values: any) => {
    try {
      await commands.execute(AddOrUpdateUnit, {
        ...unit,
        unitId,
        sourceCourseId: originalCourseId,
        name: values.name,
        icon: previewIcon,
        background: previewBackground,
      });

      setToast(
        new Toast({
          message: unitId ? 'Unit updated!' : 'Unit created!',
          severity: 'success',
          open: true,
          autoHideDuration: 3000,
        }),
      );
      navigate(-1);
    } catch (err) {
      console.error(err);
      const error = err as AxiosError;
      const uniqueNameViolation = (error.response?.data as Record<string, any>)?.message?.includes(
        'already exists',
      );
      const message = uniqueNameViolation && 'Please use a unique unit name';
      setToast(new Toast({ message, severity: 'error', open: true }));
    }
  };

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit }) => (
        <>
          <Grid container justifyContent='center' alignItems='center' height='70%'>
            <Grid height='100%' container flexDirection='column' xs={10} rowSpacing={4}>
              <Grid item justifyContent='center'>
                <Typography variant='p50Light' color='secondary.main'>
                  {editing ? 'Edit Unit' : 'Create Unit'}
                </Typography>
              </Grid>

              <Grid item justifyContent='flex-start' alignItems='flex-start'>
                <KeyValueLabel
                  keyString='Unit name'
                  value={unit?.name}
                  fieldName='name'
                  layout='stacked'
                  inputStyles={{ width: '20%' }}
                  editing
                  required
                  maxLength={100}
                />
              </Grid>

              <Grid item>
                <MediaEditView
                  variant='dark'
                  mediaType={MediaType.ICON}
                  initialMedia={unit?.icon ?? null}
                  onMediaChange={setPreviewIcon}
                />
              </Grid>

              <Grid item>
                <MediaEditView
                  variant='dark'
                  mediaType={MediaType.BACKGROUND}
                  initialMedia={unit?.background ?? null}
                  onMediaChange={setPreviewBackground}
                />
              </Grid>

              <Grid container flex={1} alignItems='flex-end' justifyContent='space-between'>
                <Button
                  color='secondary'
                  variant='outlined'
                  onClick={onCancel}
                  sx={{ width: '250px' }}>
                  Cancel
                </Button>

                <Button color='primary' onClick={handleSubmit} sx={{ width: '250px' }}>
                  {editing ? 'Save unit' : 'Create unit'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Form>
  );
};

export default UnitEditView;
