import { createTheme, ThemeProvider } from '@mui/material';
import React from 'react';

declare module '@mui/material/Paper' {
  // These customizations are for cards, but the card variants come from paper
  interface PaperPropsVariantOverrides {
    selected: true;
    recommended: true;
    disabled: true;
    ghost: true;
    innerCard: true;
    primaryLight: true;
    current: true;
    embeddedCard: true;
  }
}

declare module '@mui/material/TableCell' {
  interface TableCellPropsVariantOverrides {
    hasButton: true;
    dataHead: true;
    dataBodyKey: true;
    dataBodyValue: true;
    outlined: true;
  }
}

declare module '@mui/material/TableBody' {
  interface TableBodyPropsVariantOverrides {
    deletableRows: true;
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    ghost: true;
    activeGhost: true;
    tertiary: true;
  }

  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    p12: true;
    p12Bold: true;
    p12SemiBold: true;
    p13: true;
    p14: true;
    p14Bold: true;
    p14SemiBold: true;
    p16: true;
    p16Light: true;
    p16Bold: true;
    p16Italic: true;
    p16SemiBold: true;
    p18: true;
    p18Bold: true;
    p18SemiBold: true;
    p18Italic: true;
    p18Light: true;
    p20: true;
    p20Bold: true;
    p20SemiBold: true;
    p24: true;
    p24Bold: true;
    p24SemiBold: true;
    p26: true;
    p30: true;
    p30Bold: true;
    p30Light: true;
    p30SemiBold: true;
    p32Light: true;
    p40: true;
    p40Bold: true;
    p50Light: true;
    p60: true;
    p60Bold: true;
  }
}
declare module '@mui/material/styles' {
  // These are properties that we added inside the primary{}, secondary{}, etc. objects
  interface PaletteColor {
    main: string;
    active?: string;
    hover?: string;
    disabled?: string;
    dark: string;
  }
  interface PaletteColorOptions {
    main?: string;
    active?: string;
    hover?: string;
    disabled?: string;
    light?: string;
    dark?: string;
  }
  interface Palette extends PaletteOptions {}
  interface PaletteOptions {
    tertiary?: PaletteColorOptions;
  }

  interface TypographyVariants {
    p12: React.CSSProperties;
    p12Bold: React.CSSProperties;
    p12SemiBold: React.CSSProperties;
    p13: React.CSSProperties;
    p14: React.CSSProperties;
    p14Bold: React.CSSProperties;
    p14SemiBold: React.CSSProperties;
    p16: React.CSSProperties;
    p16Light: React.CSSProperties;
    p16Bold: React.CSSProperties;
    p16Italic: React.CSSProperties;
    p16SemiBold: React.CSSProperties;
    p18Bold: React.CSSProperties;
    p18SemiBold: React.CSSProperties;
    p18Italic: React.CSSProperties;
    p18Light: React.CSSProperties;
    p20: React.CSSProperties;
    p20Bold: React.CSSProperties;
    p20SemiBold: React.CSSProperties;
    p24: React.CSSProperties;
    p24Bold: React.CSSProperties;
    p24SemiBold: React.CSSProperties;
    p26: React.CSSProperties;
    p30: React.CSSProperties;
    p30Bold: React.CSSProperties;
    p30Light: React.CSSProperties;
    p32Light: React.CSSProperties;
    p40: React.CSSProperties;
    p40Bold: React.CSSProperties;
    p50Light: React.CSSProperties;
    p60: React.CSSProperties;
    p60Bold: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    p12?: React.CSSProperties;
    p12Bold?: React.CSSProperties;
    p12SemiBold?: React.CSSProperties;
    p13?: React.CSSProperties;
    p14?: React.CSSProperties;
    p14Bold?: React.CSSProperties;
    p14SemiBold: React.CSSProperties;
    p16?: React.CSSProperties;
    p16Light?: React.CSSProperties;
    p16Bold?: React.CSSProperties;
    p16Italic?: React.CSSProperties;
    p16SemiBold?: React.CSSProperties;
    p18?: React.CSSProperties;
    p18Bold?: React.CSSProperties;
    p18SemiBold?: React.CSSProperties;
    p18Italic?: React.CSSProperties;
    p18Light?: React.CSSProperties;
    p20?: React.CSSProperties;
    p20Bold?: React.CSSProperties;
    p20SemiBold?: React.CSSProperties;
    p24?: React.CSSProperties;
    p24Bold?: React.CSSProperties;
    p24SemiBold?: React.CSSProperties;
    p26?: React.CSSProperties;
    p30?: React.CSSProperties;
    p30Bold?: React.CSSProperties;
    p30Light?: React.CSSProperties;
    p30SemiBold?: React.CSSProperties;
    p32Light?: React.CSSProperties;
    p40?: React.CSSProperties;
    p40Bold?: React.CSSProperties;
    p50Light?: React.CSSProperties;
    p60?: React.CSSProperties;
    p60Bold?: React.CSSProperties;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#038ab7',
      active: '#02607f',
      hover: '#02769d',
      disabled: '#d4ebf2',
    },
    secondary: {
      main: '#4a606a',
      active: '#022a3a',
      hover: '#7a8e96',
      disabled: '#e6e9ea',
      dark: '#022a3a',
      light: '#B6C0C4',
    },
    info: { main: '#7a8e96' },
    error: { main: '#9a5238', active: '#f15d7f', dark: '#ce0b24', hover: '#e16d7a' },
    tertiary: {
      main: '#4d9927',
      hover: '#3f7e20',
      active: '#4f793a',
      disabled: '#b8d6a9',
    },
  },
  typography: {
    fontFamily: 'Open Sans',
    h1: {
      fontSize: 32,
    },
    h2: {
      fontSize: 18,
    },
    h3: {
      fontSize: 16,
      fontWeight: 600,
    },
    h4: {
      fontSize: 14,
      fontWeight: 600,
    },
    h5: {
      fontSize: 14,
      fontStyle: 'italic',
    },
    subtitle1: {
      fontSize: 24,
      fontWeight: 600,
    },
    body1: {
      fontSize: 16,
    },
    button: {
      fontSize: 18,
      fontWeight: 600,
    },
    // for custom variants, you need to re-specify the font family
    p12: {
      fontSize: 12,
      fontFamily: 'Open Sans',
    },
    p12Bold: {
      fontSize: 12,
      fontWeight: 'bold',
      fontFamily: 'Open Sans',
    },
    p12SemiBold: {
      fontSize: 12,
      fontWeight: 600,
      fontFamily: 'Open Sans',
    },
    p13: {
      fontSize: 13,
      fontFamily: 'Open Sans',
    },
    p14: {
      fontSize: 14,
      fontFamily: 'Open Sans',
    },
    p14Bold: {
      fontSize: 14,
      fontWeight: 'bold',
      fontFamily: 'Open Sans',
    },
    p14SemiBold: {
      fontSize: 14,
      fontFamily: 'Open Sans',
      fontWeight: '600',
    },
    p16: {
      fontSize: 16,
      fontFamily: 'Open Sans',
    },
    p16Light: {
      fontSize: 16,
      fontWeight: 300,
      fontFamily: 'Open Sans',
    },
    p16Bold: {
      fontSize: 16,
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
    },
    p16Italic: {
      fontSize: 16,
      fontStyle: 'italic',
      fontFamily: 'Open Sans',
    },
    p16SemiBold: {
      fontSize: 16,
      fontFamily: 'Open Sans',
      fontWeight: '600',
    },
    p18: {
      fontSize: 18,
      fontFamily: 'Open Sans',
    },
    p18Bold: {
      fontSize: 18,
      fontWeight: 'bold',
      fontFamily: 'Open Sans',
    },
    p18SemiBold: {
      fontSize: 18,
      fontWeight: '600',
      fontFamily: 'Open Sans',
    },
    p18Italic: {
      fontSize: 18,
      fontStyle: 'italic',
      fontFamily: 'Open Sans',
    },
    p18Light: {
      fontSize: 18,
      fontWeight: 300,
      fontFamily: 'Open Sans',
    },
    p20: {
      fontSize: 20,
      fontFamily: 'Open Sans',
    },
    p20Bold: {
      fontSize: 20,
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
    },
    p20SemiBold: {
      fontSize: 20,
      fontFamily: 'Open Sans',
      fontWeight: 600,
    },
    p24: {
      fontSize: 24,
      fontFamily: 'Open Sans',
    },
    p24SemiBold: {
      fontSize: 24,
      fontFamily: 'Open Sans',
      fontWeight: 600,
    },
    p24Bold: {
      fontSize: 24,
      fontWeight: 'bold',
      fontFamily: 'Open Sans',
    },
    p26: {
      fontSize: 26,
      fontFamily: 'Open Sans',
    },
    p30: {
      fontSize: 30,
      fontFamily: 'Open Sans',
    },
    p30Bold: {
      fontSize: 30,
      fontWeight: 'bold',
      fontFamily: 'Open Sans',
    },
    p30Light: {
      fontSize: 30,
      fontWeight: 300,
      fontFamily: 'Open Sans',
    },
    p30SemiBold: {
      fontSize: 30,
      fontWeight: '600',
      fontFamily: 'Open Sans',
    },
    p32Light: {
      fontSize: 32,
      fontWeight: 300,
      fontFamily: 'Open Sans',
    },
    p40: {
      fontSize: 40,
      fontWeight: 300,
      fontFamily: 'Open Sans',
    },
    p40Bold: {
      fontSize: 40,
      fontWeight: 'bold',
      fontFamily: 'Open Sans',
    },
    p50Light: {
      fontSize: 50,
      fontWeight: 300,
      fontFamily: 'Open Sans',
    },
    p60: {
      fontSize: 60,
      fontFamily: 'Open Sans',
    },
    p60Bold: {
      fontSize: 60,
      fontWeight: 'bold',
      fontFamily: 'Open Sans',
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          'boxShadow': 'none',
          'backgroundColor': 'transparent',
          ':before': {
            opacity: '0',
          },
          '& expanded': {
            transform: 'none',
          },
        },
      },
      defaultProps: {
        disableGutters: true,
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          flexDirection: 'row-reverse',
        },
        content: {
          '&.Mui-expanded': {
            margin: '0',
          },
        },
        expandIconWrapper: {
          '&.Mui-expanded': {
            transform: 'none',
          },
          'marginRight': '10px',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.severity === 'success' && {
            backgroundColor: '#b8d6a9',
            color: '#4f793a',
          }),
          ...(ownerState.severity === 'error' && {
            backgroundColor: '#fbb6bf',
            color: '#ce0b24',
          }),
          ...(ownerState.severity === 'warning' &&
            {
              /* fill in if we ever use it */
            }),
          ...(ownerState.severity === 'info' &&
            {
              /* fill in if we ever use it */
            }),
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          textTransform: 'none',
          fontSize: 18,
          fontWeight: 'normal',
          lineHeight: '2em',
          padding: '5px 15px',
          ...(ownerState.color === 'primary' && {
            'backgroundColor': theme.palette.primary.main,
            'color': '#fff',
            '&:hover': {
              backgroundColor: theme.palette.primary.hover,
            },
            '&:active': {
              backgroundColor: theme.palette.primary.active,
            },
            '&:disabled': {
              backgroundColor: theme.palette.primary.disabled,
              color: '#fff',
            },
          }),
          ...(ownerState.color === 'secondary' && {
            'backgroundColor': theme.palette.secondary.main,
            'color': '#fff',
            '&:hover': {
              backgroundColor: theme.palette.secondary.hover,
            },
            '&:active': {
              backgroundColor: theme.palette.secondary.active,
            },
            '&:disabled': {
              backgroundColor: theme.palette.secondary.disabled,
            },
          }),
          ...(ownerState.color === 'tertiary' && {
            'backgroundColor': theme.palette.tertiary?.main,
            'color': '#fff',
            '&:hover': {
              backgroundColor: theme.palette.tertiary?.hover,
            },
            '&:active': {
              backgroundColor: theme.palette.tertiary?.active,
            },
            '&:disabled': {
              backgroundColor: theme.palette.tertiary?.disabled,
            },
          }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'primary' && {
              'backgroundColor': '#ffffff',
              'color': theme.palette.primary.main,
              '&:hover': {
                backgroundColor: '#e2e6e7',
              },
              '&:active': {
                backgroundColor: '#d9d9d9',
                color: theme.palette.primary.active,
              },
            }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'secondary' && {
              'backgroundColor': '#ffffff',
              'color': theme.palette.secondary.main,
              '&:hover': {
                backgroundColor: '#e2e6e7',
              },
              '&:active': {
                backgroundColor: '#d9d9d9',
                color: theme.palette.secondary.active,
              },
            }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'tertiary' && {
              'backgroundColor': '#ffffff',
              'color': theme.palette.tertiary?.main,
              '&:hover': {
                backgroundColor: '#e2e6e7',
              },
              '&:active': {
                backgroundColor: '#d9d9d9',
                color: theme.palette.tertiary?.active,
              },
            }),

          ...(ownerState.variant === 'ghost' && {
            'border': 'none',
            'backgroundColor': '#ffffff',
            'color': theme.palette.secondary.main,
            '&:hover': {
              backgroundColor: '#f2f2f2',
            },
            '&:active': {
              backgroundColor: '#d9d9d9',
            },
          }),
          ...(ownerState.variant === 'activeGhost' && {
            'border': 'none',
            'backgroundColor': '#ffffff',
            'color': theme.palette.primary.main,
            '&:hover': {
              backgroundColor: '#f2f2f2',
            },
            '&:active': {
              backgroundColor: '#d9d9d9',
            },
          }),
          ...(ownerState.className === 'onDisabled' && {
            '&.Mui-disabled': {
              backgroundColor: '#f0f4f5',
              color: theme.palette.secondary.main,
              borderColor: theme.palette.info.main,
            },
          }),
          ...(ownerState.className === 'currentLearnMore' && {
            'border': 'none',
            'backgroundColor': '#ffffff',
            'color': theme.palette.tertiary?.main,
            'textDecorationLine': 'underline',
            '&:hover': {
              backgroundColor: '#ffffff',
            },
            '&:active': {
              backgroundColor: '#ffffff',
            },
          }),
          ...(ownerState.className === 'learnMore' && {
            'border': 'none',
            'backgroundColor': '#ffffff',
            'color': theme.palette.tertiary?.main,
            'textDecorationLine': 'underline',
            '&:hover': {
              backgroundColor: '#ffffff',
            },
            '&:active': {
              backgroundColor: '#ffffff',
            },
          }),
          ...(ownerState.className === 'activeLearnMore' && {
            'border': 'none',
            'backgroundColor': theme.palette.tertiary?.main,
            'color': '#ffffff',
            'textDecorationLine': 'underline',
            '&:hover': {
              backgroundColor: theme.palette.tertiary?.hover,
            },
            '&:active': {
              backgroundColor: theme.palette.tertiary?.active,
            },
          }),
          ...(ownerState.className === 'linkLearnMore' && {
            'border': 'none',
            'color': theme.palette.secondary.main,
            'background': '#f0f4f5',
            'textDecorationLine': 'underline',
            '&:hover': {
              backgroundColor: '#f0f4f5',
            },
            '&:active': {
              backgroundColor: '#f0f4f5',
            },
          }),
          ...(ownerState.className === 'xs' && {
            width: '10%',
          }),
          ...(ownerState.className === 'sm' && {
            width: '15%',
          }),
          ...(ownerState.className === 'md' && {
            width: '20%',
          }),
          ...(ownerState.className === 'lg' && {
            width: '25%',
          }),
          ...(ownerState.className === 'xl' && {
            width: '40%',
          }),
          ...(ownerState.className === 'xxl' && {
            width: '50%',
          }),
          ...(ownerState.className === 'xxxl' && {
            width: '100%',
          }),
          ...(ownerState.className === 'xxxxl' && {
            width: '125%',
          }),
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          'borderRadius': '8px',
          '& .grip': {
            'fill': theme.palette.secondary.light,
            '&:hover': {
              cursor: 'pointer',
              fill: theme.palette.secondary.hover,
            },
          },
          '& .grip-disabled': {
            'fill': theme.palette.secondary.light,
            '&:hover': {
              cursor: 'default',
            },
          },
          '& .ellipses': {
            'width': '30px',
            '&:hover': {
              cursor: 'pointer',
            },
          },
          ...(ownerState.className === 'disabled' && {
            '& *': {
              cursor: 'auto !important',
            },
            '& .grip, .ellipses': {
              opacity: '0 !important',
            },
          }),
          ...(ownerState.className === 'disabled-menu' && {
            '& .ellipses': {
              cursor: 'auto !important',
              opacity: '0 !important',
            },
          }),
          ...(ownerState.color === 'primary' && {
            backgroundColor: '#ffffff',
            border: `1px solid ${theme.palette.info.main}`,
            color: theme.palette.secondary.main,
          }),
          ...(ownerState.variant === 'primaryLight' && {
            border: 'none',
            backgroundColor: '#e7f3f9',
            color: theme.palette.secondary.main,
          }),
          ...(ownerState.color === 'secondary' && {
            backgroundColor: '#dfeef4',
            border: 'none',
          }),
          ...(ownerState.color === 'transparent' && {
            backgroundColor: 'transparent',
            border: 'none',
          }),
          ...(ownerState.variant === 'elevation' && {
            border: `5px solid ${theme.palette.info.main}`,
          }),
          ...(ownerState.variant === 'recommended' && {
            border: '5px solid #4d9927',
          }),
          ...(ownerState.variant === 'selected' && {
            border: '1px solid #ffffff',
            backgroundColor: theme.palette.tertiary?.main,
            color: '#ffffff !important',
          }),
          ...(ownerState.variant === 'ghost' &&
            ownerState.color === 'primary' && {
              border: 'none',
              backgroundColor: 'rgba(3, 138, 183, 0.05)',
              color: theme.palette.secondary.dark,
            }),
          ...(ownerState.variant === 'ghost' &&
            ownerState.color === 'secondary' && {
              border: 'none',
              backgroundColor: '#fff',
              color: theme.palette.secondary.main,
            }),
          ...(ownerState.variant === 'innerCard' && {
            border: 'none',
            backgroundColor: 'rgba(79, 173, 204, 0.2)',
            color: theme.palette.secondary.main,
          }),
          ...(ownerState.variant === 'current' && {
            backgroundColor: '#f0f4f5',
            border: `1px solid ${theme.palette.info.main}`,
            color: theme.palette.secondary.main,
          }),
          ...(ownerState.className === 'card-display' && {
            'display': 'flex',
            'alignItems': 'center',
            'justifyContent': 'center',
            'border': '1px solid #9eacb0',
            'cursor': ownerState.onClick ? 'pointer' : 'default',
            '&:hover': {
              backgroundColor: '#e6e9ea',
            },
            '&.Mui-focused': {
              backgroundColor: '#e6e9ea',
            },
          }),
          ...(ownerState.className === 'course-item-card' && {
            'display': 'flex',
            'alignItems': 'center',
            'justifyContent': 'space-between',
            'columnGap': '40px',
            'padding': '10px 20px',
            '& .MuiSvgIcon-root': {
              color: `${theme.palette.secondary.main} !important`,
              opacity: '1 !important',
            },
            '& .ellipses': {
              width: '30px',
              color: theme.palette.secondary.main,
            },
            '&:hover': {
              'color': theme.palette.secondary.hover,
              'cursor': 'pointer',
              '& .ellipses': {
                color: `${theme.palette.primary.main} !important`,
              },
              '& .MuiSvgIcon-root:not(.ellipses)': {
                color: `${theme.palette.secondary.hover} !important`,
              },
              '& svg:not(.ellipses)': {
                opacity: 0.7,
                color: theme.palette.secondary.hover,
              },
              '& .course-name, & .MuiTypography-p12, & .MuiTypography-p16, & .MuiTypography-p16 strong':
                {
                  color: theme.palette.secondary.hover,
                },
              '& .status-dot-red.visible': {
                backgroundColor: `${theme.palette.error.hover} !important`,
              },
            },
          }),
          ...(ownerState.className === 'media-item-card' && {
            'padding': '5px 10px',
            'border': `1.5px solid ${theme.palette.secondary.light}`,
            'cursor': 'pointer',
            '&:hover': {
              border: '2px solid #4fadcc',
            },
          }),
          ...(ownerState.className === 'media-item-card-selected' && {
            'padding': '5px 10px',
            'border': `2px solid ${theme.palette.primary.main}`,
            'cursor': 'pointer',
            '&:hover': {
              border: `2px solid ${theme.palette.primary.hover}`,
            },
          }),
          ...(ownerState.className?.includes('unit-lesson-card') && {
            '&.disabled': {
              opacity: 0.5,
              pointerEvents: 'none',
            },
            '& .MuiLink-root': {
              'textDecoration': 'none',
              '&:hover': {
                textDecoration: 'none',
              },
            },
            '&:hover': {
              'cursor': 'pointer',
              '& .MuiTypography-root:not(.tippy-box *)': {
                color: theme.palette.secondary.hover,
              },
              '& .status-dot-red.visible': {
                backgroundColor: `${theme.palette.error.hover} !important`,
              },
            },
          }),
        }),
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.className === 'basic' && {
            padding: '16px !important',
          }),
          ...(ownerState.className === 'deletable' && {
            padding: '16px 50px 16px 16px !important',
          }),
          ...(ownerState.className === 'dataTable' && {
            padding: '16px 50px !important',
          }),
          ...(ownerState.className === 'basicCard' && {
            padding: '30px 30px 35px 50px !important',
          }),
          ...(ownerState.className === 'communications' && {
            padding: '30px 50px !important',
          }),
          ...(ownerState.className === 'innerContent' && {
            margin: '0px 30px !important',
          }),
          ...(ownerState.className === 'helpChoose' && {
            margin: '0px 10px !important',
            textAlign: 'center',
            height: '50%',
          }),
          ...(ownerState.className === 'contract' && {
            padding: '30px 30px 30px 50px !important',
          }),
          ...(ownerState.className === 'pad0' && {
            padding: '0px !important',
          }),
          ...(ownerState.className === 'pad5' && {
            padding: '5px !important',
          }),
          ...(ownerState.className === 'pad10' && {
            padding: '10px !important',
          }),
          ...(ownerState.className === 'pad15' && {
            padding: '15px !important',
          }),
          ...(ownerState.className === 'pad20' && {
            padding: '20px !important',
          }),
          ...(ownerState.className === 'pad25' && {
            padding: '25px !important',
          }),
          ...(ownerState.className === 'pad30' && {
            padding: '30px !important',
          }),
          ...(ownerState.className === 'centered-inner' && {
            padding: '16px !important',
            alignItems: 'center',
          }),
          ...(ownerState.className === 'reviewCost' && {
            padding: '30px !important',
            textAlign: 'center',
          }),
          ...(ownerState.className === 'yearlyPrice' && {
            padding: '20px 30px !important',
          }),
          ...(ownerState.className === 'whoPays' && {
            padding: '10px 20px !important',
          }),
          ...(ownerState.className === 'packageCard' && {
            padding: '20px 35px !important',
          }),
          ...(ownerState.className === 'profileCard' && {
            padding: '10px 10px 30px 50px !important',
          }),
          ...(ownerState.className === 'purchaseAdditional' && {
            padding: '10px 45px !important',
          }),
          ...(ownerState.className === 'searchbox' && {
            padding: '25px 50px !important',
          }),
          ...(ownerState.className === 'addressCard' && {
            padding: '10px 0px 20px 0px !important',
          }),
          ...(ownerState.className === 'mobileAddressCard' && {
            padding: '10px 5px !important',
          }),
          ...(ownerState.className === 'memberSignupCard' && {
            padding: '15px 40px  !important',
          }),
          '& .tiptap': {
            'display': 'inline-block',
            'wordBreak': 'break-word',
            'width': '100%',
            'outline': 'none !important',
            'fontWeight': 300,
            '& *': {
              backgroundColor: '#ffffff !important',
              color: theme.palette.secondary.active,
              fontSize: '16px',
              fontFamily: 'Open Sans',
              lineHeight: 1.5,
              fontStretch: 'normal',
            },
          },
          '& .live-preview-editor': {
            border: 'solid 1px #979797',
            borderRadius: '7px',
            backgroundColor: '#ffffff',
            padding: '20px',
            paddingBottom: '10px',
            paddingTop: '10px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '10px',
            width: '100%',
          },
        }),
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          '&.Mui-checked': {
            color: theme.palette.primary,
          },
          ...(ownerState.className === 'parent' && {
            '&.Mui-checked': {
              color: theme.palette.secondary.main,
            },
            '&.MuiCheckbox-indeterminate': {
              color: theme.palette.secondary.main,
            },
            '&:hover': {
              backgroundColor: '#e6e9ea',
            },
          }),
          ...(ownerState.className === 'child' && {
            'color': '#b6c0c4',
            '&.Mui-checked': {
              color: '#ffffff',
            },
            '&:hover': { backgroundColor: 'transparent' },
          }),
        }),
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Open Sans';`,
    },
    MuiDialog: {
      styleOverrides: {
        root: () => ({
          /* fill in if we need */
        }),
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: () => ({
          /* fill in if we need */
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: () => ({
          backgroundColor: theme.palette.secondary.main,
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          color: theme.palette.secondary.dark,
          label: {
            fontSize: 18,
            fontWeight: 600,
          },
          ...(ownerState.className === 'parent' && {
            'color': theme.palette.secondary.main,
            '&:hover': {
              color: theme.palette.secondary.dark,
            },
            'label': {
              fontSize: 18,
              fontWeight: 600,
            },
          }),
          ...(ownerState.className === 'itemUnchecked' && {
            'borderRadius': '20px',
            'border': 'solid 1px #979797',
            'borderColor': '#979797',
            'color': theme.palette.secondary.dark,
            'backgroundColor': '#ffffff',
            '&:hover': {
              backgroundColor: '#e6e9ea',
            },
            'label': {
              fontSize: 16,
            },
          }),
          ...(ownerState.className === 'itemChecked' && {
            'borderRadius': '20px',
            'border': 'solid 1px #979797',
            'borderColor': '#9eacb0',
            'color': '#ffffff',
            'backgroundColor': '#9eacb0',
            '&:hover': {
              backgroundColor: theme.palette.secondary.hover,
            },
            'label': {
              fontSize: 16,
            },
          }),
        }),
      },
    },
    MuiLink: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          '&:hover': {
            cursor: 'pointer',
          },
          ...(ownerState.className === 'underlineOnHover' && {
            'textDecorationLine': 'none',
            '&:hover': {
              textDecorationLine: 'underline !important',
              cursor: 'pointer !important',
              overflow: 'visible !important',
            },
          }),
          ...(ownerState.className === 'darkenOnHover' && {
            'textDecoration': 'none',
            '&:hover': {
              color: theme.palette.secondary.hover,
              cursor: 'pointer',
            },
          }),
        }),
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.className === 'clickableListItem' && {
            cursor: 'pointer',
            padding: '5px 0px 5px 0px',
            position: 'relative',
            ...(ownerState.divider && {
              'borderBottom': '1px solid #b6c0c4',
              '&:last-child': {
                borderBottom: 'none',
              },
            }),
          }),
        }),
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&:hover, &:focus': {
            backgroundColor: '#f0f4f5',
            borderRadius: '8px',
          },
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: () => ({
          /* fill in if we need */
        }),
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: () => ({
          '& .MuiPaper-root': {
            marginTop: '15px',
            marginRight: '20px',
            border: '1px solid #9eacb0',
            borderRadius: '8px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          },
          '& .MuiPickersDay-root.Mui-selected': {
            'backgroundColor': theme.palette.primary.main,
            'color': theme.palette.primary.contrastText,
            '&:focus': {
              backgroundColor: theme.palette.primary.main,
            },
          },
          '& .MuiPickersDay-today': {
            borderColor: theme.palette.primary.light,
          },
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          'color': `#4a606a`,
          '&$checked': {
            color: '#038ab7 !important',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.className?.includes('bordered') && {
            'borderBottom': '2px solid #9eacb0',
            'textTransform': 'capitalize',
            'color': theme.palette.secondary.main,
            '&.Mui-selected': {
              borderTop: '2px solid #9eacb0',
              borderLeft: '2px solid #9eacb0',
              borderRight: '2px solid #9eacb0',
              borderBottom: 'none',
              color: theme.palette.primary.main,
            },
          }),
        }),
      },
    },
    MuiTable: {
      styleOverrides: {
        root: () => ({
          color: theme.palette.secondary.dark,
        }),
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.className === 'user-accounts' && {
            display: 'table',
            width: '100%',
            tableLayout: 'fixed',
          }),
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          padding: '16px 0px',
          ...(ownerState.variant === 'hasButton' && {
            ':last-of-type': {
              textAlign: 'right',
              borderBottom: 'none',
              fontSize: 16,
            },
          }),
          ...(ownerState.variant === 'head' && {
            borderBottom: `1px solid ${theme.palette.secondary.main}`,
            fontSize: 18,
            fontWeight: 600,
            color: theme.palette.secondary.dark,
          }),
          ...(ownerState.variant === 'body' && {
            borderBottom: 'none',
            fontSize: 16,
            color: theme.palette.secondary.dark,
          }),
          ...(ownerState.variant === 'dataHead' && {
            border: 'none',
            fontSize: 18,
            color: theme.palette.secondary.dark,
            padding: '5px 10px',
            textAlign: 'right',
            fontWeight: 600,
          }),
          ...(ownerState.variant === 'dataBodyKey' && {
            border: 'none',
            fontSize: 16,
            color: theme.palette.info.main,
            padding: '5px 10px',
            textAlign: 'right',
          }),
          ...(ownerState.variant === 'dataBodyValue' && {
            border: 'none',
            fontSize: 16,
            color: theme.palette.secondary.dark,
            padding: '5px 10px',
          }),
          ...(ownerState.variant === 'outlined' && {
            border: `1px solid ${theme.palette.info.main}`,
            paddingRight: 20,
            paddingLeft: 20,
          }),
          ...(ownerState.className === 'paddedHeader' && {
            paddingRight: 20,
            paddingLeft: 20,
          }),
        }),
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          backgroundColor: 'rgba(3, 138, 183, 0)',
          boxShadow: 'none',
          ...(ownerState.className === 'scrollable' && {
            maxHeight: 500,
            overflow: 'scroll',
          }),
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.bordered': {
            '& .MuiTabs-indicator': {
              display: 'none',
            },
            '& .MuiTab-root': {
              'borderBottom': '2px solid #9eacb0',
              'textTransform': 'capitalize',
              'color': theme.palette.secondary.main,
              '&.Mui-selected': {
                color: theme.palette.primary.main,
                borderBottom: 'transparent',
              },
            },
          },
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.className === 'date-picker' && {
            '& .MuiInputBase-root': {
              'cursor': 'pointer',
              '& fieldset': {
                border: 'none',
              },
            },
            '& .MuiInputBase-input': {
              'fontSize': '16px',
              'textAlign': 'left',
              'cursor': 'pointer',
              'userSelect': 'none',
              '&:focus': {
                backgroundColor: 'transparent',
              },
            },
          }),
        }),
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: () => ({
          'textTransform': 'none',
          'border': `1px solid ${theme.palette.info.main}`,
          'outline': 'none',
          'color': theme.palette.info.main,
          'fontSize': '14px',
          '&.Mui-selected': {
            backgroundColor: '#7a8396',
            color: 'white',
          },
          '&.Mui-selected:hover': {
            backgroundColor: '#6a7d85', // TODO update this once we have designs
          },
        }),
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: () => ({
          backgroundColor: 'white',
          // outline: `1px solid ${theme.palette.info.main}`,
          outline: 'none',
        }),
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          textTransform: 'none',
          wordWrap: 'break-word',
          ...(ownerState.className === 'bullet' && {
            'display': 'block',
            'position': 'relative',
            'paddingLeft': '20px',
            '&::before': {
              content: "'•'",
              position: 'absolute',
              left: 0,
            },
          }),
          ...(ownerState.className === 'lesson-content-title' && {
            '& *': {
              fontFamily: 'inherit !important',
              fontSize: 'inherit !important',
              fontWeight: 'inherit !important',
              color: 'inherit !important',
            },
          }),
          ...(ownerState.className === 'lesson-content-copy' && {
            '& *': {
              color: 'inherit !important',
            },
          }),
        }),
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.className === 'live-preview' && {
            'display': 'flex',
            'flexDirection': 'column',
            'borderBottom': 'solid 2px #f0f4f5',
            '& .live-preview-content': {
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            },
            '& .draft, .preview': {
              paddingTop: '16px',
              paddingBottom: '16px',
              paddingRight: '40px',
              paddingLeft: '40px',
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
              rowGap: '20px',
            },
            '& .draft': {
              borderRight: 'solid 1px #f0f4f5',
            },
            '& .preview': {
              borderLeft: 'solid 1px #f0f4f5',
            },
          }),
          ...(ownerState.className === 'live-preview-header' && {
            '& .MuiButtonBase-root': {
              height: '40px',
            },
            '& .draft': {
              borderRight: 'solid 1px #f0f4f5',
            },
            '& .preview': {
              borderLeft: 'solid 1px #f0f4f5',
            },
            '& .draft, .preview': {
              width: '50%',
              display: 'flex',
              paddingRight: '40px',
              paddingLeft: '40px',
              paddingTop: '10px',
              paddingBottom: '10px',
              justifyContent: 'space-between',
            },
            'display': 'flex',
            'justifyContent': 'space-between',
            'alignItems': 'center',
            'width': '100%',
          }),
          ...(ownerState.className === 'live-preview-toolbar' && {
            '& .is-active': {
              backgroundColor: theme.palette.grey.A400,
            },
            '& .MuiButtonBase-root': {
              borderRadius: '2px',
              padding: '2px',
            },
          }),
          ...((ownerState.className === 'quiz-question' ||
            ownerState.className === 'quiz-answer') && {
            display: 'flex',
            flexDirection: 'column',
            rowGap: '10px',
          }),
          ...(ownerState.className === 'quiz-answer-input' && {
            'display': 'flex',
            'columnGap': '10px',
            '& .input': {
              flexGrow: 1,
            },
            '& > .MuiButtonBase-root': {
              padding: 0,
            },
          }),
        }),
      },
    },
  },
});

const ThemeWrapper: React.FC<React.PropsWithChildren> = props => {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export default ThemeWrapper;
