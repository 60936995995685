import { PaymentPartyEnum } from './LicenseData';
import { NewStepData } from './SignUpData';
import { Svgs } from '../assets/svg';
import React from 'react';

export interface OffboardingWizardState {}

export const InitialOffboardingState: OffboardingWizardState = {};
export type PackageChoice = { package: PackageChoicesEnum; whoPays: PaymentPartyEnum };

export enum PackageChoicesEnum {
  Nickels,
  NickelsU,
  Guidedchoice,
}
export interface OffboardingStepData extends NewStepData {
  totalSteps: number;
  activeStep: number;
}

// /* entry point: dashboard => exit point: dashboard */
// export const OffboardingStepsDashboardDefault: OffboardingStepData[] = [
//   { current: '/', route: '/uploadLogo', totalSteps: 3, activeStep: 0 },
//   { current: '/uploadLogo', route: '/restrictMembers', totalSteps: 3, activeStep: 1 },
//   { current: '/restrictMembers', route: '/customUrl', totalSteps: 3, activeStep: 2 },
//   { current: '/customUrl', route: '/', totalSteps: 3, activeStep: 3 },
// ];

// /* entry point: profile => exit point: profile */
// export const OffboardingStepsProfileDefault: OffboardingStepData[] = [
//   { current: '/profile', route: '../uploadLogo', totalSteps: 3, activeStep: 0 },
//   { current: '/uploadLogo', route: '../restrictMembers', totalSteps: 3, activeStep: 1 },
//   { current: '/restrictMembers', route: '../customUrl', totalSteps: 3, activeStep: 2 },
//   { current: '/customUrl', route: '../profile', totalSteps: 3, activeStep: 3 },
// ];
/* entry point: congrats (after signup) => exit point: dashboard */
export const OffboardingStepsDefault: OffboardingStepData[] = [
  { current: '/uploadLogo', route: '/uploadLogo', totalSteps: 3, activeStep: 1 },
  { current: '/restrictMembers', route: '/restrictMembers', totalSteps: 3, activeStep: 2 },
  { current: '/customUrl', route: '/customUrl', totalSteps: 3, activeStep: 3 },
  { current: 'home', route: '/', totalSteps: 3, activeStep: 0 },
];

export const MemberPaysOffboardingSteps: OffboardingStepData[] = [
  { current: '/uploadLogo', route: '/uploadLogo', totalSteps: 3, activeStep: 1 },
  { current: '/restrictMembers', route: '/restrictMembers', totalSteps: 3, activeStep: 2 },
  { current: '/customUrl', route: '/customUrl', totalSteps: 3, activeStep: 3 },
  { current: 'home', route: '/', totalSteps: 3, activeStep: 0 },
];

export const OffboardingStepsAdp: OffboardingStepData[] = [
  { current: '/uploadLogo', route: '/uploadLogo', totalSteps: 2, activeStep: 1 },
  { current: '/customUrl', route: '/customUrl', totalSteps: 2, activeStep: 2 },
  { current: 'home', route: '/', totalSteps: 2, activeStep: 0 },
];

export interface ToDoListData {
  number: number;
  text: string;
  art: React.FunctionComponent;
}
export const DefaultToDoList: ToDoListData[] = [
  { number: 1, text: 'Upload your organization logo', art: Svgs.BulletDocument },
  { number: 2, text: 'Restrict member access', art: Svgs.BulletLock },
  { number: 3, text: 'Send out your custom URL', art: Svgs.BulletCommunications },
];

export const MemberPaysToDoList: ToDoListData[] = [
  { number: 1, text: 'Upload your organization logo', art: Svgs.BulletDocument },
  { number: 2, text: 'Restrict member access', art: Svgs.BulletLock },
  { number: 2, text: 'Send out your custom URL', art: Svgs.BulletCommunications },
];

export const AdpToDoList: ToDoListData[] = [
  { number: 1, text: 'Upload your organization logo', art: Svgs.BulletDocument },
  { number: 2, text: 'Send out your custom URL', art: Svgs.BulletCommunications },
];

export interface OrgDomain {
  id: number;
  domain: string;
  organizationId: number;
}
