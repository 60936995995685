import { useAppStorage, useObservable } from '@aesop-fables/scrinium';
import { Card, CardContent, Typography, Button, Link } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { ToastbarContext } from '../../App';
import { Column, Spacer, DownloadButton, Row } from '../../components';
import { OrganizationCompartments, organizationStorageKey } from '../../data/organization';
import { useDeviceType } from '../../hooks/useDeviceType';
import { OrganizationData } from '../../models/OrganizationData';
import { Toast } from '../../models/Toast';

export const MemberSignUpUrl: React.FC = () => {
  const appStorage = useAppStorage();
  const { isMobile, isTablet } = useDeviceType();
  const { setToast } = useContext(ToastbarContext);
  const fullSizeThreshold = 1200;
  const forceUpdate = React.useReducer(() => ({}), {})[1]; // Function to force update

  const organizationDataCache =
    appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
  const orgData = useObservable(organizationDataCache.observe$<OrganizationData>('organization'));
  const orgUrl = orgData?.url;
  const orgQR = orgData?.qrUri;

  useEffect(() => {
    const handleResize = () => {
      forceUpdate();
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [forceUpdate]);

  const onCopy = () => {
    navigator.clipboard.writeText(orgUrl ?? '');
    setToast(new Toast({ message: 'Copied to clipboard!', open: true, severity: 'success' }));
  };

  const onDownload = () => {
    try {
      if (orgQR) {
        const link = document.createElement('a');
        link.href = orgQR;
        link.download = `${orgData.name}_QR.png`;
        link.click();
      } else {
        setToast(new Toast({ message: 'QR code not found.', open: true, severity: 'error' }));
      }
    } catch (err) {
      console.error(err);
      setToast(new Toast({ message: 'Error downloading QR code.', open: true, severity: 'error' }));
    }
  };

  return (
    <>
      {isMobile || isTablet ? (
        <Column>
          <Card variant='ghost' color='primary'>
            <CardContent className='yearlyPrice'>
              <Column style={{ alignItems: 'center' }}>
                <Typography variant='p16' color='secondary.dark'>
                  Copy URL
                </Typography>
                <Link
                  sx={{
                    textDecoration: 'underline',
                    maxWidth: '100%',
                  }}
                  variant='p12'
                  color='primary'
                  href={`https://${orgUrl}`}
                  target='_blank'
                  rel='noreferrer'>
                  {orgUrl}
                </Link>
                <Spacer height='xxxs' />
                <Button color='secondary' onClick={onCopy}>
                  <Typography variant='p12'>Copy to clipboard</Typography>
                </Button>
              </Column>
            </CardContent>
          </Card>
          <Spacer height='xs' />

          <Card
            variant='ghost'
            color='primary'
            style={{
              flex: 1,
            }}>
            <CardContent className='yearlyPrice'>
              <Column
                style={{
                  alignItems: 'center',
                }}>
                <Typography variant='p16' color='secondary.dark'>
                  Download QR Code
                </Typography>
                <Spacer height='xxxs' />
                <img
                  src={orgQR}
                  alt='QR Code'
                  style={{
                    border: '2px solid black',
                  }}
                />
                <Spacer height='xxxs' />
                <DownloadButton onDownload={onDownload} textVariant='p12' style={{ width: 105 }} />
              </Column>
              <Spacer width='xxl' />
            </CardContent>
          </Card>
        </Column>
      ) : (
        <Row
          style={{
            flexWrap: 'wrap',
            display: 'flex',
          }}>
          <Card
            variant='ghost'
            color='primary'
            style={{
              flex: 2,
              ...((window.innerWidth <= fullSizeThreshold ||
                document.documentElement.clientWidth <= fullSizeThreshold) && {
                flex: 1,
              }),
            }}>
            <CardContent
              className='memberSignupCard'
              style={{
                height: '100%',
                alignContent: 'center',
              }}>
              <Row
                style={{
                  justifyContent: 'space-between',
                  height: '85%',
                }}>
                <Column style={{ justifyContent: 'space-between' }}>
                  <Typography variant='p20' color='secondary.dark'>
                    Copy URL
                  </Typography>
                  <Button
                    sx={{
                      whiteSpace: 'nowrap',
                    }}
                    color='secondary'
                    onClick={onCopy}>
                    <Typography variant='p14'>Copy to clipboard</Typography>
                  </Button>
                </Column>
                <Spacer width='sm' />

                <Link
                  sx={{
                    width: '60%',
                    alignSelf: 'flex-end',
                    textDecoration: 'underline',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 3,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  variant='p20'
                  color='primary'
                  href={`https://${orgUrl}`}
                  target='_blank'
                  rel='noreferrer'>
                  {orgUrl}
                </Link>
                <Spacer width='xxl' />

                <Column style={{ justifyContent: 'space-between' }}>
                  <Typography variant='p20' color='secondary.dark'>
                    Download QR Code
                  </Typography>
                  <DownloadButton
                    onDownload={onDownload}
                    textVariant='p14'
                    style={{ height: 45 }}
                  />
                </Column>
                <Spacer width='md' />
                <img
                  src={orgQR}
                  alt='QR Code'
                  style={{
                    border: '2px solid black',
                  }}
                />
              </Row>
            </CardContent>
          </Card>
        </Row>
      )}
    </>
  );
};

export default MemberSignUpUrl;
