import React, { useState, useEffect } from 'react';
import { useService } from '@aesop-fables/containr-react';
import { IAutoLogoutService, autoLogoutKey } from '.';
import { IAuthenticationContext, authContextKey } from '../authentication';
import { Modal, Spacer } from '../../components';
import { Card, Grid, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import Cookies from 'js-cookie';
import { domain } from '../../configuration';
import { useAppStorage } from '@aesop-fables/scrinium';
import { UserCompartments, userStorageKey } from '../../data/user';

dayjs.extend(duration);

const SessionTimeoutModal: React.FC = () => {
  const appStorage = useAppStorage();
  const userDataCache = appStorage.retrieve<UserCompartments>(userStorageKey);

  const [open, setOpen] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const formRef = React.useRef<HTMLFormElement>(null);

  const autoLogoutService = useService<IAutoLogoutService>(autoLogoutKey);
  const authContext = useService<IAuthenticationContext>(authContextKey);

  useEffect(() => {
    const checkInterval = setInterval(() => {
      setTimeRemaining(autoLogoutService.getTimeUntilLogout());
      const shouldBeOpen = autoLogoutService.isInWarningPeriod();

      if (shouldBeOpen !== open) {
        setOpen(shouldBeOpen);
      }
    }, 1000);

    return () => clearInterval(checkInterval);
  }, [autoLogoutService, open]);

  const handleKeepWorking = async (): Promise<void> => {
    await userDataCache.reload('user');
    setOpen(false);
  };

  const handleLogout = (): void => {
    setOpen(false);
    authContext.setIsAuthenticated(false);
    formRef.current?.submit();
  };

  return (
    <>
      <form
        ref={formRef}
        method='post'
        action={`https://ipm.${domain}/logout`}
        style={{ display: 'none' }}>
        <input type='hidden' name='_csrf' value={Cookies.get('XSRF-TOKEN')} readOnly />
      </form>
      <Modal
        open={open}
        setOpen={setOpen}
        handleSave={handleKeepWorking}
        handleCancel={handleLogout}
        onClose={handleKeepWorking}
        title='Are you still working?'
        primaryButtonText='Keep working'
        secondaryButtonText='Logout'
        contentStyle={{ padding: '30px !important', minWidth: '500px' }}>
        <Grid display='flex' flex={1} flexDirection='column'>
          <Typography variant='p14' color='secondary'>
            Your session is about to expire due to inactivity.
          </Typography>
          <Spacer height='xs' />
          <Card variant='innerCard'>
            <Grid display='flex' flex={1} p={2} columnGap={1} alignItems='center'>
              <AccessTimeIcon />
              <Grid display='flex' flex={1} alignItems='center' justifyContent='space-between'>
                <Typography variant='p14SemiBold'>Time before auto-logout:</Typography>
                <Typography variant='p18Bold'>
                  {dayjs.duration(timeRemaining).format('HH:mm:ss')}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Modal>
    </>
  );
};

export default SessionTimeoutModal;
