import { Scopes, createServiceModule, inject } from '@aesop-fables/containr';
import { authContextKey } from '../authentication';
import type { IAuthenticationContext } from '../authentication';

export const autoLogoutKey = 'services/autoLogoutService';

export interface IAutoLogoutService {
  restartLogoutTimer(): void;
  getTimeUntilLogout(): number;
  isInWarningPeriod(): boolean;
}

const sessionDuration = 1800000; // 30 min until autologout
const warningDuration = 300000; // warning at 5 min
const pollInterval = 30000; // poll every 30 seconds

export class AutoLogoutService implements IAutoLogoutService {
  private timer: NodeJS.Timeout | undefined;
  private mostRecentTimeStamp: number;

  constructor(@inject(authContextKey) private readonly context: IAuthenticationContext) {
    this.mostRecentTimeStamp = Date.now();

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.checkSession();
      }
    });
  }

  private checkSession(): void {
    const timeRemaining = this.getTimeUntilLogout();
    if (timeRemaining <= 0) {
      this.context.setIsAuthenticated(false);
      if (this.timer) {
        clearInterval(this.timer);
      }
    }
  }

  getTimeUntilLogout(): number {
    return sessionDuration - (Date.now() - this.mostRecentTimeStamp);
  }

  isInWarningPeriod(): boolean {
    const timeRemaining = this.getTimeUntilLogout();
    return timeRemaining <= warningDuration && timeRemaining > 0;
  }

  restartLogoutTimer(): void {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.mostRecentTimeStamp = Date.now();
    this.timer = setInterval(() => this.checkSession(), pollInterval);
  }
}

export const withAutoLogout = createServiceModule(authContextKey, services => {
  services.autoResolve<IAutoLogoutService>(autoLogoutKey, AutoLogoutService, Scopes.Singleton);
});
